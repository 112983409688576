.multi-select-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;


  #priorityFlag {
    flex: 0 0 100%;
  }

  .items-selected {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    margin: 4px 2px;
    box-sizing: border-box;
  }

  .select__multi-value__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.8rem;
    padding: 0.3rem;
    box-sizing: border-box;
  }

  .select__multi-value__remove {
    all: unset;
    transition: fill 0.15s ease-in-out;
    cursor: pointer;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;

    -webkit-box-align: center;
    align-items: center;
    display: flex;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    &:hover {
      background-color: rgb(255, 189, 173);
      color: rgb(222, 53, 11);
    }
  }
}
